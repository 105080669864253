h2 {
    font-size: 4rem; /* Default for larger screens */
  }
  
  /* Adjust for tablet-sized screens */
  @media (max-width: 768px) {
    h2 {
      font-size: 3rem; /* Smaller size for tablets */
    }
  }
  
  /* Adjust for mobile-sized screens */
  @media (max-width: 480px) {
    h2 {
      font-size: 2rem; /* Even smaller size for mobile phones */
    }
  }
  

  h3 {
    font-size: 2rem; /* Default for larger screens */
  }
  
  /* Adjust for tablet-sized screens */
  @media (max-width: 768px) {
    h3 {
      font-size: 1.2rem; /* Smaller size for tablets */
    }
  }
  
  /* Adjust for mobile-sized screens */
  @media (max-width: 480px) {
    h3 {
      font-size: 1.1rem; /* Even smaller size for mobile phones */
    }
  }
  