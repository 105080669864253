.toastContainer {
  position: fixed;
  width: 92vw;
  padding: 0rem 4vw;
  bottom:64px; /* Adjust this to position the toast higher or lower */
  z-index: 1000; /* Ensure it's on top of other content */
  display: flex;
  justify-content: center;
  align-items: center;
}

.toastMessage {
  background-color: #ddd; /* Light gray background */
  color: #111; /* Dark text color */
  padding: 8px 32px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  display: flex;
  align-items: center;
  gap: 1rem; /* Added gap for spacing */
  font-size: 1rem;
  position: relative;
  animation: fadeIn 0.5s ease-in-out forwards,
    fadeOut 0.5s ease-in-out 5s forwards; /* Smooth transitions */
    text-align: center;
    justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.closeButton {
  background: none;
  border: none;
  color: #999; /* Set the color to #999 */
  cursor: pointer;

  transition: color 0.2s ease; /* Add a transition for hover effect */
}

.closeButton:hover {
  color: #666; /* Darken the color on hover */
}

.closeButton svg {
  width: 20px; /* Set width for the SVG icon */
  height: 20px; /* Set height for the SVG icon */
}
@media (max-width: 768px) {
  .toastMessage {
    width: 80%;}
}
