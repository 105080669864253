/* list.module.css */

.listContainer {
  display: grid; /* Enable grid layout */
  grid-template-columns: repeat(
    auto-fit, /* Max number of columns */
    minmax(240px, 1fr)
  );
  gap: 1rem; /* Space between grid items */
  padding: 16px;
  border-radius: 8px;
  margin-top: 0;

}

.emptyMessage {
  color: #ccc;
  text-align: center;
  margin-top: 4rem;
  font-size: 1.2rem;
}

.container h2 {
  margin: 0;
  text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  margin-left: 1rem;
}

.shimmerArray {
  display: grid; /* Enable grid layout */
  grid-template-columns: repeat(
    auto-fill,
    minmax(240px, 1fr)
  ); /* Adjust item size */
  gap: 1rem; /* Space between grid items */
  padding: 16px;
  border-radius: 8px;
}

.shimmerContainer {
  border-radius: 4px; /* Rounded corners */
  display: flex; /* Flex for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  aspect-ratio: 1; /* Makes the item square */
  flex-direction: column;
  position: relative;
  cursor: pointer;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: #111;
}

.shimmer {
  background: linear-gradient(90deg, #2c2c2c 25%, #3c3c3c 50%, #2c2c2c 75%);
  background-size: 200% 100%;
  animation: shimmerAnimation 2s infinite;
}

@keyframes shimmerAnimation {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}
.endMessage {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4rem 0rem;
  width: auto;
  font-size: 1.2rem;
  padding: 0rem 1rem;
  text-align: center;
}

/* Adjust for tablet-sized screens */
@media (max-width: 768px) {
  .listContainer {
    grid-template-columns: repeat(
      auto-fill,
      minmax(200px, 1fr)
    ); /* Adjust item size */
  }
  .shimmerArray {
    grid-template-columns: repeat(
      auto-fill,
      minmax(200px, 1fr)
    ); /* Adjust item size */
  }

  
}

/* Adjust for mobile-sized screens */
@media (max-width: 480px) {
  .listContainer {
    grid-template-columns: repeat(
      auto-fill,
      minmax(140px, 1fr)
    ); /* Adjust item size */
  }
  .shimmerArray {
    grid-template-columns: repeat(
      auto-fill,
      minmax(140px, 1fr)
    ); /* Adjust item size */
  }
  .endMessage{
    width: 80%;
    margin: 4rem 2rem;

  }

}
