.popularity {
    display: flex;
    align-items: center; /* Aligns dots vertically */
    justify-content: center;
    gap: 0.3rem;
    margin-top: 0.2rem;
}

.dot {
    width: 8px; /* Adjust dot size */
    height: 8px; /* Adjust dot size */
    border-radius: 50%; /* Make the dot round */
    transition: background-color 0.3s; /* Smooth transition for color changes */
    background-color: rgb(196, 196, 196);
    background-color:#4caf50;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);

}

.bigDot{
    width: 12px; /* Adjust dot size */
    height: 12px; /* Adjust dot size */
    border-radius: 50%; /* Make the dot round */
    transition: background-color 0.3s; /* Smooth transition for color changes */
    background-color: rgb(196, 196, 196);
    background-color:#4caf50;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);

}