.sortingControl {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
}
.sortingControl label {
  font-weight: bold;
}

.buttonGroup {
  display: flex;
  gap: 10px; /* Adjust the gap between buttons */
  flex-wrap: wrap;

}

.buttonStyle {
  padding: 6px 20px;
  border: none;
  border-radius: 30px;
  border: 2px solid #999; /* Light grey border */
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  background-color: #222; /* Default dark background */
  transition: all 0.3s ease; /* Smooth transition */
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  height: 36px;

}

.buttonStyle:hover {
  background-color: #eee; /* On hover, dark grey background */
  border-color: #ddd; /* Green border on hover */
  color: #111;
}

.activeButton {
  background-color: #ddd; /* Highlighted green background */
  color: #111;
  border-color: #ddd; /* White border when selected */
}
