body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: 
    radial-gradient(circle, #444 0%, rgba(0, 0, 0, 0) 80%), /* Circular gradient from corners */
    #222; /* Solid gray background for the rest */
  color: white;
  overflow-x: clip !important;
  height: 100vh; /* Full viewport height */
  background-attachment: fixed;
  background-position: top; /* Keeps the gradient aligned at the top */
}

li{
  list-style-type: none;

}