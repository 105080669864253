.container {
  border-radius: 4px; /* Rounded corners */
  display: flex; /* Flex for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  aspect-ratio: 1; /* Makes the item square */
  flex-direction: column;
  position: relative;
  cursor: pointer;
  background-color: #111;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  max-width: 340px;

}
.container:hover {
  transform: scale(1.05);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
}
.image {
  width: 100%;
  height: 100%;
  border-radius: 4px; /* Rounded corners */
  object-fit: cover;
}

.imagePlaceholder {
  width: 100%;
  height: 100%;
  border-radius: 4px; /* Rounded corners */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
}
.textContainer {
  position: absolute;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  margin: 0;
  border-radius: 0px 0px 4px 4px; /* Rounded corners */
  padding: 0.5rem 0rem;
  text-align: center;
}

.name {
  margin: 0rem;
  color: #fff;
  font-size: 1rem;
}


/* Adjust for tablet-sized screens */
@media (max-width: 768px) {

}

/* Adjust for mobile-sized screens */
@media (max-width: 480px) {
  .name {
    margin: 0rem;
    color: #fff;
    font-size: 0.8rem;
  }
}

