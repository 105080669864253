/* toggle.module.css */

.toggle {
  width: 60px; /* Width of the toggle */
  height: 30px; /* Height of the toggle */
  background-color: #ddd; /* Background color when off */
  border-radius: 15px; /* Rounded edges */
  position: relative; /* For positioning the circle */
  cursor: pointer; /* Cursor changes to pointer on hover */
  transition: background-color 0.3s ease; /* Smooth background transition */
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  scale: 0.7;
  margin-top: 3.5px;

}

.toggled {
  background-color: #4caf50; /* Background color when on */
}

.toggleCircle {
  width: 31px; /* Width of the circle */
  height: 31px; /* Height of the circle */
  background-color: white; /* Circle color */
  border-radius: 15px; /* Rounded edges */
  position: absolute; /* Position the circle */
top: -0.5px;
  transition: transform 0.3s ease; /* Smooth transition for movement */
}

.toggled .toggleCircle {
  transform: translateX(28px); /* Move the circle to the right when toggled */
}

@media (max-width: 1216px) {

}