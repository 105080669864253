/* Center the spinner on the page */
.spinnerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh; /* Full viewport height */
}

/* Styling for the spinner */
.spinnerBg {
  border: 16px solid #111; /* Lighter gray border */
  border-radius: 50%; /* Makes the element circular */
  width: 70px; /* Spinner width */
  height: 70px; /* Spinner height */
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);

}
.spinner1{
  position: absolute;
  border: 16px solid rgba(0, 0, 0, 0.0); /* Lighter gray border */
  border-top: 16px solid #4caf50; /* Primary color (Green) for the filled section */
  border-radius: 50%; /* Makes the element circular */
  width: 70px; /* Spinner width */
  height: 70px; /* Spinner height */
  animation: spin 1s ease-in-out infinite; /* Smooth infinite spinning */
}

.spinner2{
  position: absolute;
  border: 16px solid rgba(0, 0, 0, 0.0); /* Lighter gray border */
  border-top: 16px solid #4caf50; /* Primary color (Green) for the filled section */
  border-radius: 50%; /* Makes the element circular */
  width: 70px; /* Spinner width */
  height: 70px; /* Spinner height */
  animation: spin 1s ease-in-out infinite ; /* Smooth infinite spinning */
  animation-delay: 0.75s;
}

.spinner3{
  position: absolute;
  border: 16px solid rgba(0, 0, 0, 0.0); /* Lighter gray border */
  border-top: 16px solid #4caf50; /* Primary color (Green) for the filled section */
  border-radius: 50%; /* Makes the element circular */
  width: 70px; /* Spinner width */
  height: 70px; /* Spinner height */
  animation: spin 1s ease-in-out infinite; /* Smooth infinite spinning */
  animation-delay: 0.35s;


}


/* Define the spinning keyframes */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
