.container {
    margin-top: -4rem;
  }
  
  .mobileControl {
    position: absolute;
    top: -24px;
    right: 64px;
    z-index: 100000;
    display: flex;
    gap: 0.5rem;
  }
  .mobileControl .upArrow {
    border-color: #999;
  }
  .mobileControlIsSticky {
    position: fixed;
    top: 7px;
  }
  .artistInfo {
    background-color: #111; /* Light background for card */
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    flex-direction: column;
    position: relative;
  }
  .albumCardShimmer {
    background-color: #111; /* Light background for card */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    min-height: 200px;
    width: 1200px;
    width: 100%;
  }
  
  .shimmer {
    background: linear-gradient(90deg, #2c2c2c 25%, #3c3c3c 50%, #2c2c2c 75%);
    background-size: 200% 100%;
    animation: shimmerAnimation 2s infinite;
  }
  
  @keyframes shimmerAnimation {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }
  
  .artistInfo h2 {
    margin: 0;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
    margin-left: 1rem;
  }
  .albumList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column; /* Arrange items vertically */
    max-width: 1200px; /* Limit width of the list */
    margin: 0px auto; /* Center the list */
    color: #1e1e1e;
    padding: 1rem;
    padding-top: 0rem;
    /* padding-right: 1.5rem; */
  }
  .albumListShimmer {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column; /* Arrange items vertically */
    max-width: 1200px; /* Limit width of the list */
    margin: 0px auto; /* Center the list */
    color: #1e1e1e;
    padding: 1.2rem;
    padding-top: 0rem;
    width: 100%;
    gap: 4rem;
    margin-top: 4rem;
  }
  
  .albumCard {
    display: flex;
    background-color: #111; /* Light background for card */
    border-radius: 8px; /* Rounded corners */
    transition: box-shadow 0.3s; /* Smooth shadow transition */
    margin-bottom: 2.5rem;
    position: relative;
    color: white;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
  }
  
  .albumItem {
    opacity: 0; /* Start invisible */
    transform: translateY(20px); /* Start slightly lower */
    animation: fadeIn 0.5s forwards; /* Use animation for fade-in */
  }
  .listContainer {
    margin-top: 4rem;
    opacity: 0; 
    transition: opacity 0.5s ease-in; 
  }
  
  .listContainer.visible {
    opacity: 1; 
  }
  /* Keyframe for fade-in effect */
  @keyframes fadeIn {
    to {
      opacity: 1; /* Fully visible */
      transform: translateY(0); /* Move to original position */
    }
  }
  /* Define the keyframes for fading in */
  @keyframes fadeInInfo {
    from {
      opacity: 0; /* Start fully transparent */
    }
    to {
      opacity: 1; /* End fully opaque */
    }
  }
  
  /* Apply the fade-in animation to the artistInfo class */
  .info {
    animation: fadeInInfo 0.8s ease-in forwards; /* Animate with defined keyframes */
  }
  
  .infoRight {
    width: 100%;
  }
  .imageWrapper {
    width: 20%; /* Takes up 20% of the parent container */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensures no overflow */
    background-color: #222; /* Optional: Fallback background color */
    border-radius: 4px 0 0 4px; /* Maintain rounded corners */
  }
  
  .image {
    width: 100%; /* Scale image to fit the width of the wrapper */
    height: auto; /* Maintain the aspect ratio */
    object-fit: contain; /* Ensures the entire image fits without distortion */
    border-radius: inherit; /* Inherit the rounded corners from the wrapper */
  }
  
  .albumDetails {
    flex: 2 1 80%; /* Album details take up 2/3 of the card width */
    display: flex;
    flex-direction: column;
    padding: 1rem;
    padding-right: 2.5rem;
  }
  
  .albumName {
    margin: 0;
    font-weight: bold;
    align-self: self-start;
    padding-right: 2rem;
  }
  
  .detailsRow {
    display: flex;
    justify-content: space-between; /* Space the items evenly */
    margin: 4px 0; /* Spacing for rows */
  }
  
  .releaseDate,
  .albumType {
    color: #fff; /* Lighter color for date and album type */
    font-size: 1.2rem;
    text-transform: capitalize;
  }
  .albumType {
    font-weight: bold;
  }
  .totalTracks {
    font-weight: 500;
  }
  .totalTracks,
  .albumNumber {
    color: #ffffff; /* Lighter color for date and album type */
    font-size: 1rem;
  }
  
  .headerRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .albumNumber {
    position: absolute;
    background-color: #111;
    color: white;
    border-radius: 4px;
    height: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -0px;
    top: -16px;
    border: 2px solid #a8a8a8;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.5);
    font-size: 1rem;
  }
  .spotifyLink {
    color: #1db954; /* Spotify's green color */
    text-decoration: none;
    margin-top: auto;
    font-weight: bold;
    width: fit-content;
  }
  
  .spotifyLink:hover {
    text-decoration: underline;
  }
  
  .yearHeading {
    font-size: 4rem;
    margin: 0;
    margin-bottom: 4px;
    text-shadow: 5px 5px 5px rgba(0, 0, 0, 0.5);
  }
  .controls {
    margin: 0;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    max-width: 1200px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
  }
  .amount {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    max-width: 1200px;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
  .amount p {
    margin: 0;
  }
  .toggleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }
  .toggleContainer p {
    font-weight: bold;
  }
  .shimmerContainer {
    margin: 16px 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    max-width: 1200px;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
  }
  
  .timeContainer {
    gap: 0.5rem;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: auto;
  }
  .otherArtists {
    padding-bottom: 1rem;
    padding-top: 0.5rem;
  
    font-size: 1rem;
  }
  
  .info {
    display: flex;
    max-width: 1200px;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 0rem;
    margin-top: 2rem;
  }
  .info img {
    width: 30%;
    min-width: 300px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  }
  .info a {
    color: white;
    font-weight: bold;
  }
  .info h2 {
    margin: 0;
  }
  
  .genres {
    font-weight: bold;
    display: flex;
    font-size: 1rem;
    align-items: center;
    gap: 0.5rem 0.5rem;
    margin-top: 8px;
    line-height: 1.6;
    flex-wrap: wrap;
  }
  .info p {
    font-size: 1rem; /* Slightly larger than default */
    line-height: 1.6; /* Good readability */
    color: #ffffff; /* A dark gray for softer text */
    margin-bottom: 15px; /* Spacing between paragraphs */
    border-radius: 5px; /* Slightly rounded corners */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin-top: 0.5rem;
  }
  .genreItem {
    font-weight: 500;
    font-size: 0.8rem !important;
    text-transform: capitalize;
    margin: 0rem !important;
    border-radius: 30px !important;
    padding: 0rem 1rem;
    background-color: #ddd;
  }
  
  .albumDetails h4,
  .albumDetails h4 {
    margin: 0;
  }
  
  .popularity {
    display: flex;
  }
  .popularity h3 {
    font-size: 1rem !important;
    margin: 0rem;
    margin-right: 1rem;
  }
  .upArrow {
    padding: 12px 12px !important;
  }
  .extendSearch {
    align-self: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4rem 0rem;
    margin-top: 0rem;
    width: auto;
    font-size: 1.2rem;
    padding: 0rem 1rem;
    flex-direction: column;
    text-align: center;
  }
  .extendSearch button {
    border-color: #999;
  }
  @media (max-width: 1216px) {
    .imageWrapper {
      width: 50%;
    }
    .albumDetails{
      width: 50%;
    }
    .yearHeading {
      padding-left: 1rem;
    }
    .mobileControl {
      position: fixed;
      top: initial;
      left: initial;
  
      bottom: 16px;
      right: 16px;
      z-index: 2;
    }
    .controls,
    .amount {
      margin: 1rem 1rem;
    }
    .controls {
      margin-bottom: 0.5rem;
      gap: 0.5rem;
    }
    .amount {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
    }
    .extendSearch {
      width: 80%;
      margin-top: 0rem;
      margin-right: auto;
      margin-left: auto;
    }
  }
  
  /* Adjust for mobile-sized screens */
  @media (max-width: 480px) {
    .imageWrapper {
      width: 100%;
      margin-bottom: 0px;
    }
    .info {
      flex-direction: column;
    }
  
    .albumCard {
      flex-direction: column; /* Stack items vertically on smaller screens */
      align-items: flex-start; /* Align items to the start */
    }
  
    .albumDetails {
      flex: none;
      padding: 4%;
      width: 92%;
    }
    .detailsRow {
      justify-content: space-between;
      width: fit-content;
      width: 100%;
      align-items: center;
    }
    .headerRow {
      align-items: start;
    }
  }
  .cardHover {
    transition: scale 0.3s ease-in-out; /* Smooth transition */
  }
  .cardHover:hover {
    scale: 1.02;
  }
  .slideOutDetails {
    max-height: 0; /* Initially hidden */
    overflow: hidden; /* Hide overflowing content */
    background-color: #999; /* Background color for the sliding div */
    transition: max-height 0.3s ease-in-out, padding 0.3s ease; /* Smooth transition */
    margin-top: -2rem;
    margin-bottom: 2rem;
    border-radius: 0px 0px 8px 8px;
  }
  
  .slideOutDetails.open {
    max-height: 480px;
    overflow-y: auto;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
  }
  
  /* Custom dark-themed scrollbar */
  .slideOutDetails.open::-webkit-scrollbar {
    width: 12px; /* Width of the scrollbar */
  }
  
  .slideOutDetails.open::-webkit-scrollbar-track {
    background: #999; /* Dark background for the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
  }
  
  .slideOutDetails.open::-webkit-scrollbar-thumb {
    background-color: #222; /* Slightly lighter dark color for the thumb */
    border-radius: 10px; /* Rounded corners for the thumb */
    border: 2px solid #222; /* Border to create some space inside the scrollbar */
  }
  
  .slideOutDetails.open::-webkit-scrollbar-thumb:hover {
    background-color: #333; /* Lighter shade on hover for better visibility */
  }
  
  .slideOutDetails.open::-webkit-scrollbar-thumb:active {
    background-color: #333; /* Even lighter shade when actively dragging */
    background-color: #333; /* Even lighter shade when actively dragging */
  
    background-color: #222; /* Even lighter shade when actively dragging */
  
    background-color: #111; /* Even lighter shade when actively dragging */
  }
  .tracksContainer {
    list-style: none; /* Remove default list styling */
    padding: 0;
    margin: 0;
    border-radius: 8px;
    padding: 1rem; /* Add padding inside the container */
    column-count: 1; /* Single column by default */
    column-gap: 2rem; /* Gap between columns */
  }
  
  /* For larger screens, switch to two columns */
  @media (min-width: 768px) {
    .tracksContainer {
      column-count: 2; /* Two columns for larger screens */
    }
  }
  
  .tracksContainer li {
    break-inside: avoid; /* Prevents breaking inside a list item */
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    background-color: #222;
    border-radius: 6px;
    transition: background-color 0.3s ease;
    display: flex;
  }
  .trackInfo {
  }
  .tracksContainer li:hover {
    background-color: #333; /* Change background color on hover */
  }
  
  .tracksContainer li strong {
    color: #1db954; /* Spotify green for track number */
    margin-right: 0.8rem;
    font-weight: bold;
  }
  
  .tracksContainer li span {
    color: #fff; /* Light text for track name */
    font-size: 1rem;
  }
  
  .duration {
    margin-left: auto;
    padding-left: 1rem;
    align-self: self-start;
  }
  
  @media (max-width: 768px) {
    .albumCard {
      margin-bottom: 2rem;
    }
  
    .info {
      flex-direction: column;
    }
    .slideOutDetails.open {
      max-height: 300px;
      overflow-y: auto;
    }
  }
  
  .sortingControl,
  .sortingToggle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .sortingControl select,
  .sortingToggle button {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .toggleButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
  }
  
  .toggleButton:hover {
    background-color: #0056b3;
  }
  