.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
  z-index: 5;
  height: 62px;
}

.searchBackground {
  background-color: #999;
  display: flex;
  padding: 0.5rem 0.5rem;
  border-radius: 60px;
  gap: 0.5rem;
  display: flex;
  justify-content: center;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.stickyActive {
  height: 62px;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: -1px;
  background-color: #111111dd;
  transform: translateZ(0); /* Force hardware acceleration */
  z-index: 1000; /* Ensure sticky element stays above other content */
  transition: background-color 0.3s ease;
}
.stickyActiveBackground {
  background-color: rgba(153, 153, 153, 0); /* Use rgba for transparency */
  box-shadow: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; 
}

.stickyActiveBackground .inputContainer {
  border: 2px solid #999;
}

.inputContainer {
  display: flex;
  align-items: center;
  background-color: #222;
  border-radius: 30px;
  padding: 0px 20px;
}

input {
  width: 100%;
  border: none;
  border-radius: 30px;
  outline: none;
  font-size: 1rem;
  color: #fff;
  background-color: transparent;
  padding: 0.5rem 1rem; /* Add padding for mobile devices */
}

@media (max-width: 768px) {
  input {
    font-size: 0.9rem;
    padding: 0.4rem 0.8rem;
  }
  button {
    padding: 10px 16px;
  }
}

input::placeholder {
  color: #999;
}

button {
  padding: 12px 20px;
  border: none;
  border-radius: 30px;
  border: 2px solid #4caf50; /* Spotify green */
  color: rgb(255, 255, 255);
  font-size: 1rem;
  cursor: pointer;
  background-color: #222;
  transition: background-color 0.3s ease; /* Smooth transition */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  height: 46px;
}
.stickyArtistName {
  position: absolute;
  top: 0;
  left: 64px;
  margin: 0rem;
  font-size: 1rem;
  height: 62px;
  display: flex;
  align-items: center;
  max-width: 300px;
  color: #ffffff;
}
.hideStickyArtistName {
  display: none;
}
button:hover {
  background-color: #333; /* Darker green on hover */
}

button:focus {
  outline: none; /* Remove focus outline */
}

button:active {
  background-color: #333; /* Even darker on click */
}

@media (max-width: 1216px) {
  .stickyArtistName {
    display: none;
  }
}

@media (max-width: 768px) {
  .searchBackground {
    margin: 0rem 0.5rem;
  }
  /* .stickyActive {
    transition: none;
  }
  .stickyActiveBackground {
    transition: none;
  } */
}
