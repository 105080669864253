.container {
  position: relative;
  padding-top: 4rem;
}

.container h1 {
  text-align: center;
  font-size: 3rem;
  margin: 0;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
}
.account {
  position: absolute;
  top: 16px;
  right: 16px;
}
main {
  min-height: 100vh;
}

.artistName {
  font-size: 6rem;
  font-weight: 1000;
  text-shadow: 5px 5px 10px rgba(0, 0, 0,0.5);
  letter-spacing: 1rem;
  letter-spacing: 0.2rem;
  color: #4caf50;
  word-break: break-all;
  padding: 0rem 1rem;

}

.inOrder {
  font-weight: bold;
  letter-spacing: 1rem;
  font-size: 1.8rem;
  margin: 0;
  text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  margin-left: 1rem;
  font-family: "Plus Jakarta Sans";
  margin-top:0.5rem;
}


a {
  text-decoration: none;
}
.animatedLetter {
  display: inline-block;
  opacity: 0;
  animation: fadeInDown 0.25s ease-out forwards; /* Fast fade-in */
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* .logo {

  position: absolute;
  top: 0;
  right: 12px;
} */

.logo {
  height: auto; /* Maintain the aspect ratio */
  max-width: 80px; /* Limit the maximum width for larger screens */
  position: absolute;
  top: 16px;
  right: 12px;
}

/* Adjust for tablet */
@media (max-width: 768px) {
  .logo {
    display: none;
  }
}

/* Adjust for mobile */
@media (max-width: 480px) {
  .logo {
  }
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .artistName {
    font-size: 4rem; /* Adjusted font size for tablet screens */
    letter-spacing: 0.1rem;
  }
  /* .logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
  } */

  .inOrder {
    font-size: 1.2rem; /* Adjusted font size for tablet screens */
    letter-spacing: 0.3rem;
    margin-left: 0.5rem;
    
  }
}

@media (max-width: 480px) {
  .container {
    padding-top: 1rem;
  }
 
  .artistName {
    font-size: 3rem; /* Smaller font size for mobile phones */
    letter-spacing: 0.05rem;
  }
}

.wordWrapper {
  display: inline-block; /* Keeps the word as a block but allows wrapping */
  word-break: wrap;
  white-space: wrap;
}
