.footer {
  background-color: #111;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  font-family: "Arial", sans-serif;
  font-size: 15px; /* Slightly increased for readability */
  line-height: 1.6; /* Improves readability */
  width: 92%;
  padding: 4%;
  bottom: 0;
  margin-top: 4rem;
}

.footerLeft,
.footerRight {
  display: flex;
  flex-direction: column;
}

.footerLeft {
  width: 60%;
  padding-right: 20px; /* Adds space between the columns */
}

.footerLeft h3 {
  margin-bottom: 10px; /* Adds space below the heading */
  display: flex;
  align-items: center;
  gap: 1rem;
}

.footerLeft p {
  margin-bottom: 15px; /* Adds space between paragraphs */
}

.footerRight {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footerRight a {
  text-decoration: none;
  color: #fff;
  font-size: 1.4rem; /* Increased icon size */
}

.footerRight a:hover {
  color: #1db954; /* Spotify green on hover */
}

.copyright {
  margin-top: 20px;
  text-align: center;
  color: #888;
  width: 100%;
}

.footerBottom {
  text-align: center;
  width: 100%;
  font-size: 0.9rem;
  color: #aaa;
  margin-top: 10px;
}

.socialIcon {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Increased gap for better spacing */
}

.socialIcon i {
  font-size: 24px; /* Increased icon size */
}
.spotifyLogo {
  width: 100px;
}
@media (max-width: 768px) {
  .footerLeft {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    padding-right: 0;
    justify-content: center;
    align-items: center;
  }
  

  .footerRight {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .spotifyLogo {
    margin: 1rem auto;
  }
  .socialIcon {
    justify-content: center;
  }
}

.logo {
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: center;
}
